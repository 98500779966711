export default {
  data() {
    return {
      echartElm: null,
    }
  },
  computed: {
    isCollapse() {
      return this.$store.state.isCollapse
    },
  },
  watch: {
    isCollapse() {
      setInterval(() => {
        this.chartResizeHandler()
      }, 1000)
    },
  },
  mounted() {
    this.initResizeEvent()
    this.initSidebarResizeEvent()
  },

  beforeDestroy() {
    this.destroyResizeEvent()
    this.destroySidebarResizeEvent()
  },

  activated() {
    // 后面如果使用keep-alive后使用，再次进入
    this.initResizeEvent()
    this.initSidebarResizeEvent()
  },
  deactivated() {
    // 后面如果使用keep-alive后使用，离开时
    this.destroyResizeEvent()
    this.destroySidebarResizeEvent()
  },
  methods: {
    chartResizeHandler() {
      if (this.echart) {
        setTimeout(() => {
          this.echart.resize()
        }, 200)
      }
    },
    sidebarResizeHandler(e) {
      if (e.propertyName === 'width')
        this.chartResizeHandler()
    },
    initResizeEvent() {
      if (this.chartResizeHandler)
        window.addEventListener('resize', this.chartResizeHandler)
    },
    destroyResizeEvent() {
      if (this.chartResizeHandler)
        window.removeEventListener('resize', this.chartResizeHandler)
    },
    initSidebarResizeEvent() {
      this.echartElm = document.getElementsByClassName(this.echartElm)[0]
      if (this.echartElm) {
        // 完成动画后调用
        this.echartElm.addEventListener('transitionend', this.sidebarResizeHandler)
      }
    },
    destroySidebarResizeEvent() {
      if (this.echartElm)
        this.echartElm.removeEventListener('transitionend', this.sidebarResizeHandler)
    },
  },
}
