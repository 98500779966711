<template>
  <div class="chart2"
    ref="echart">
  </div>
</template>

<script>
  import echarts from 'echarts';

  export default {
    data() {
      return {
        echart: null,
      };
    },
    props: {
      width: {
        type: Number,
        required: true,
      },
      subtext: {
        type: String,
        required: true,
      },
      legendData: {
        type: Array,
        required: true,
      },
      seriesData: {
        type: Array,
        required: true,
      },
    },
    watch: {
      seriesData() {
        this.echartInit();
      },
      deep: true,
    },
    methods: {
      echartInit() {
        this.echart = echarts.init(this.$refs.echart, 'macarons');
        const { seriesData } = this;
        const total = seriesData.reduce(((accumulator, currentValue) => accumulator + currentValue.value), 0);
        const option = {
          title: {
            text: total,
            textStyle: { color: '#fff', fontFamily: 'YouSheBiaoTiHei', fontSize: '30' },
            subtext: this.subtext,
            subtextStyle: { color: '#acacac', fontSize: '14' },
            left: 'center',
            top: '150',
          },
          legend: {
            icon: 'circle',
            left: 'center',
            padding: [0, 0, 0, 0],
            top: '320',
            data: this.legendData,
            width: this.width,
            itemWidth: 8,
            itemHeight: 8,
            formatter(name) {
              const per = Object.is(NaN, seriesData.find((item) => item.name === name).value / total) ? 0 : seriesData.find((item) => item.name === name).value / total * 100;
              const legendName = name.length > 6 ? `${name.substring(0, 6)}...` : name;
              return [
                `{a|${legendName}}`,
                `{b|${per.toFixed(per % 1 === 0 ? 0 : 1)}%}`,
              ].join('');
            },
            textStyle: {
              color: '#F1F1F3',
              padding: [3, 0, 0, 0],
              rich: {
                a: {
                  width: 70,
                },
                b: {
                  width: 50,
                  align: 'right',
                },
              },
            },
          },
          color: ['#FF6600', '#FFCC00', '#CCFF99', '#99CCFF', '#66CCCC'],
          series: [
            {
              type: 'pie',
              radius: ['100', '115'],
              top: 'top',
              center: ['50%', '185'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              labelLine: {
                show: false,
              },
              data: seriesData,
              roundCap: true,
            },
          ],
        };
        this.echart.setOption(option);
      },
    },
    mounted() {
      this.echartInit();
    },
  };
</script>

<style lang="less" scoped>
  .chart2 {
    height: 400px;
  }
</style>
