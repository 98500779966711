<template>
  <ul>
    <li v-for="item in filterOption"
      :key="item.value"
      @click="change(item)"
      :class="{active: selected === item.value}">
      {{item.txt}}
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      filterOption: {
        required: true,
      },
    },
    data() {
      return {
        selected: this.filterOption[0].value,
      };
    },
    methods: {
      change(item) {
        this.selected = item.value;
        this.$emit('change', this.selected);
      },
    },
  };
</script>

<style scoped lang="less">
  ul {
    position: absolute;
    display: inline-block;
    font-size: 12px;
    border-radius: 22px;
    border: 1px solid #acacac;
    right: 0;
    z-index: 1;
    top: 18px;
    li {
      display: inline-block;
      padding: 2px 12px;
      color: #acacac;
      cursor: pointer;
    }
    li.active {
      color: #02d6dc;
    }
  }
</style>
